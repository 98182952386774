import Vue from 'vue';
import Router from 'vue-router';
import Starter from './pages/MantisManIndex.vue';
import StarterNavbar from './layout/MantisManNavbar.vue';
import StarterFooter from './layout/MantisManFooter.vue';
import AboutUs from "@/pages/AboutUs";
import dronebyshooting from "@/pages/dronebyshooting";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: '/',
      name: 'index',
      components: {
        default: Starter,
        header: StarterNavbar,
        footer: StarterFooter
      },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/aboutUs',
      name: 'AboutUs',
      components: { default: AboutUs, header: StarterNavbar, footer: StarterFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/dronebyshooting',
      name: 'dronebyshooting',
      components: { default: dronebyshooting, header: StarterNavbar, footer: StarterFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      }
    }
  ],
  scrollBehavior: to => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  }
});
