<template>
  <navbar
      position="fixed"
      type="primary"
      :transparent="transparent"
      :color-on-scroll="colorOnScroll"
      menu-classes="ml-auto"
  >
    <template>
      <div v-popover:popover1 class="navbar-brand" to="/AboutUs">
        Menu
      </div>
      <el-popover
        ref="popover1"
        popper-class="popover"
        placement="bottom"
        width="250"
        trigger="hover"
      >
        <div class="popover-body">
          <router-link to="/AboutUs">About MantisMan®</router-link>
        </div>
        <div class="popover-body">
          <b>Aerial Drone Photography Services</b>
          <div class="popover-body">
            MantisMan® - Fly Over
          </div>
          <div class="popover-body">
            MantisMan® - Drone By Shooting
          </div>
        </div>
        <div class="popover-body">
          Traditional Photography
        </div>
        <div class="popover-body">
          Video Post Production
        </div>
        <div class="popover-body">
          Digital Imagery Production
        </div>
        <div class="popover-body">
          Full Stack Web Development
        </div>
        <div class="popover-body">
          SQL Database Development
        </div>
        <div class="popover-body">
          Computer Skills Coaching
        </div>
        <div class="popover-body">
          Custom Desktop/Workstation Builds
        </div>
        <div class="popover-body">
          <a href="https://folding.extremeoverclocking.com/team_summary.php?s=&t=264056">Our Folding@Home Stats</a>
        </div>
      </el-popover>
    </template>
    <template slot="navbar-menu">
      <li class="nav-item">
        <a
            class="nav-link"
            rel="tooltip"
            title="Home"
            data-placement="bottom"
            href="/"
        >
          <i class="fab el-icon-s-home"></i>
          <p class="d-lg-none d-xl-none">Home</p>
        </a>
      </li>
      <li class="nav-item">
        <a
            class="nav-link"
            rel="tooltip"
            title="Follow us on Facebook"
            data-placement="bottom"
            href="https://www.facebook.com/MantisManLLC"
            target="_blank"
        >
          <i class="fab fa-facebook-square"></i>
          <p class="d-lg-none d-xl-none">Facebook</p>
        </a>
      </li>
      <li class="nav-item">
        <a
            class="nav-link"
            rel="tooltip"
            title="Please like our YouTube Channel"
            data-placement="bottom"
            href="https://www.youtube.com/channel/UC67Lwl6moGDTkoKqNFaY_Hg"
            target="_blank"
        >
          <i class="fab fa-youtube"></i>
          <p class="d-lg-none d-xl-none">YouTube</p>
        </a>
      </li>
      <drop-down tag="li" title="Explore">
        <i><b>Photo Galleries</b></i><br>
        <nav-link class="dropdown-item">
            <i><a style="color: #00bbff" href="https://mantisman.com/gallery/PhotoCity_20210529/album/index.html">A Night At Photo City Music Hall</a></i><br>
        </nav-link>
        <nav-link class="dropdown-item">
            <i><a style="color: #00bbff" href="https://mantisman.com/gallery/drakon_restore/album/index.html">Rusting 2004 BMW 330xi Body Restored By Ultra Fine Collision</a></i>
        </nav-link>
      </drop-down>
    </template>
  </navbar>
</template>

<script>
import { DropDown, Navbar, NavLink } from '@/components';
import { Popover } from 'element-ui';

export default {
  name: 'main-navbar',
  props: {
    transparent: Boolean,
    colorOnScroll: Number
  },
  components: {
    DropDown,
    Navbar,
    NavLink,
    [Popover.name]: Popover
  }
};
</script>

<style scoped></style>
