<template>
  <div id="app">
    <router-view name="header" />
    <div class="wrapper">
      <router-view />
    </div>
    <router-view name="footer" />
  </div>
</template>
<script>
import { EmbedPlugin } from 'bootstrap-vue'
import Vue from "vue";
Vue.use(EmbedPlugin);
export default {};
</script>
