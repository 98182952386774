<template>
 <div>
<div class="page-header clear-filter">
  <parallax
      class="page-header-image"
      style="background-image:url('img/header_mm2.jpg')"
  >
  </parallax>
  <div class="container">
    <div class="h1-seo"></div>
    <div class="content-center brand">
      <img class="n-logo" src="img/MM_Logo_landing_inverse.gif" alt="" />
      <div style="backdrop-filter: blur(5px); text-align: left;"><p><b><span style="color: #00bbff">What is MantisMan®?</span> MantisMan® is a boutique provider of multiple service types and disciplines and project management that go into the creation and operation of complex custom computer applications and solutions.
        These may range from the production of original art for use in web and print publications, the coding of desktop or online accessible applications and the specification and provisioning of needed computer hardware and equipment.
        We have talent that has worked many years at developing enterprise services in areas such as E-Commerce, Digital Asset Management, Content Management Systems, Education Registration and On-boarding, Enterprise Database Development with MySQL and MSSQL, ETL, Information Technology and Hardware, as well as in Graphic Arts and Photography. Today's
        basic websites are hardly basic. Their complexity requires a multi-disciplined approach to execute on successfully.
        <br><br>
        We are also pleased to announce that we will soon be including Arial Drone Photography as part of our service offerings. We have FAA regulation Part 107 certification for sUAS systems and we are currently testing and developing our production and dissemination process before we go live for hire with these services. In the mean time, we will be posting samples and doing some pro-bono productions as proof of ability. While we are still in Beta phase, if you would like to work with us and have property we can fly over and film located near to our Rochester NY office, please reach out.
        <br><br>
        MantisMan's founder and lead developer - John Glassman, migrated from Professional Photography and Print Production in the early 1990's to building secure E-Commerce shopping and merchant tools via web applications using Netscape Enterprise LiveWire (the original server side Javascript platform long before node.js) and Informix database. He then worked
        with the University of Rochester Simon School of Business to create their first online web based course registration system and other administrative tool sets. Following, he spent years with companies such as Applied Graphic Technologies, Xerox,
        and SRC Solutions Inc developing complex data driven web based applications, now commonly known as 'Full Stack Development'. John has collaborated on and often lead many complex projects, using multiple technologies, and has taken them from proposal into design and all the way into production.</b></p>
        <p><b>MantisMan®
          is now looking for select new projects to develop. We can put together a team to meet your goals or also work with resources you already have available but in need of leadership or additional specialized skill sets.</b></p></div>
      <div style="backdrop-filter: blur(5px); text-align: left;"><p><b>Please feel free to reach out to us with your project development interests.
        We are most interested in perusing select projects utilizing BootstrapVue frontend with Spring backend and MySQL database data persistence, but we have full capabilities in a verity of software disciplines and imaging production. Please reach out directly to our owner and lead developer <a href="mailto: jglassman@MantisMan.com">John Glassman</a> with a brief description of your needs and we will be happy to explore if we could be a good fit for each other.</b></p></div>
      <div style="backdrop-filter: blur(5px); text-align: left;"><p><b>We are still working on our launch, so please forgive us if our site is yet a bit under developed but we are updating it often! Thanks for checking us out.</b></p></div>
    </div>
  </div>
</div>
</div>
</template>
<script>
import { Parallax } from '@/components';
export default {
  name: 'AboutUsS',
  bodyClass: 'index-page',
  components: {
    Parallax //,
    //BasicElements,
    //Navigation,
    //TabsSection,
    //ProgressPagination,
    //Notifications,
    //Typography,
    //JavascriptComponents,
    //CarouselSection,
    //NucleoIconsSection,
    //SignupForm,
    //ExamplesSection,
    //DownloadSection
  }
};
</script>
<style>
.starter-page {
  min-height: calc(100vh - 95px);
}
</style>
