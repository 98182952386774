<template>
  <footer
    class="footer"
    :class="{ [`footer-${type}`]: type }"
    :data-background-color="backgroundColor"
  >
    <div class="container">
      <nav>
        <ul>
          <li>
            MantisMan LLC - &nbsp;
          </li>
          <li>
            <router-link to="/AboutUs">
              About Us
            </router-link>
          </li>
          <!--<li>
            <router-link to="/blog">
              Blog
            </router-link>
          </li>
          <li>
            <router-link to="/tos">
              Terms Of Service
            </router-link>
          </li>-->
        </ul>
      </nav>
      <div class="copyright">
        &copy; {{ year }}, Designed and Code Implemented By MantisMan LLC
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  props: {
    backgroundColor: String,
    type: String
  },
  data() {
    return {
      year: new Date().getFullYear()
    };
  }
};
</script>
<style></style>
