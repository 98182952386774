<template>
  <div>
    <div class="page-header clear-filter">
      <parallax
          class="page-header-image"
          style="background-image:url('img/header_mm2.jpg')"
      >
      </parallax>
      <div class="container">
        <div class="h1-seo"></div>
        <div class="content-center brand">
          <img class="n-logo" src="img/MM_Logo_landing_inverse.gif" alt="" />
          <h3 class="h1-seo">MantisMan LLC</h3>
          <h3>Solutions For The Future Now!
          <br>Our next great project could be yours!</h3>
          </div>
        <!--<div  class="MantisManBadges"><img src="img/MantisMan_FlyOver_med.png" height="138" width="255"/></div>
        <div  class="MantisManBadges"><img src="img/MantisMan_FlyOver_med.png" height="138" width="255"/></div>-->
        <div class="content-center2 brand">

          <!--<iframe width="560" height="315" src="https://video.mantisman.com:/WebRTCApp/play.html?name=863491216030863424741075&playOrder=vod" frameborder="0" allowfullscreen></iframe>-->
          <!--<div class="container-md"><iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/IkQr8WTFz-c" title="YouTube video player" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>-->
          <b-embed
              type="iframe"
              aspect="16by9"
              src="https://www.youtube-nocookie.com/embed/VudQc0TwW9A"
              allowfullscreen
          ></b-embed>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Parallax } from '@/components';
export default {
  name: 'starter',
  bodyClass: 'index-page',
  components: {
    Parallax //,
    //BasicElements,
    //Navigation,
    //TabsSection,
    //ProgressPagination,
    //Notifications,
    //Typography,
    //JavascriptComponents,
    //CarouselSection,
    //NucleoIconsSection,
    //SignupForm,
    //ExamplesSection,
    //DownloadSection
  }
};
/*
import {videoPlayer} from 'vue-videojs7';
export ViedoPlayer {
  components: {
    videoPlayer
  }
}
 */

</script>
<style>
.starter-page {
  min-height: calc(100vh - 95px);
}
</style>
