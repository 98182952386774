<template>
  <div>
    <div class="page-header clear-filter">
      <parallax
          class="page-header-image"
          style="background-image:url('img/header_mm2.jpg')"
      >
      </parallax>
      <div class="container">
        <div class="h1-seo"></div>
        <div class="content-center brand">
          <img  src="img/MantisMan_DroneByShooting.gif"/>
          <div style="backdrop-filter: blur(5px); text-align: left;">
        </div>
      </div>
    </div>
  </div>
  </div>
</template>
<script>
import { Parallax } from '@/components';
export default {
  name: 'dronebyshooting',
  bodyClass: 'index-page',
  components: {
    Parallax //,
    //BasicElements,
    //Navigation,
    //TabsSection,
    //ProgressPagination,
    //Notifications,
    //Typography,
    //JavascriptComponents,
    //CarouselSection,
    //NucleoIconsSection,
    //SignupForm,
    //ExamplesSection,
    //DownloadSection
  }
};
</script>